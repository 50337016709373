
import {Component, Prop, Vue} from "vue-property-decorator";
import {TakipAltTipHesapAyarEntity} from "@/entity/TakipAltTipHesapAyarEntity";
import draggable from "vuedraggable";
import Tutar from "@/components/inputs/Tutar.vue";
import {AlacakKalemiAlaniEntity} from "@/entity/AlacakKalemiAlaniEntity";

@Component({
  components: {
    Tutar,
    draggable,
  },
})

export default class TakipAltTipAlanlarList extends Vue {
  @Prop() value!: Array<TakipAltTipHesapAyarEntity>;
  @Prop() kartaOzel!: boolean | string;

  items: Array<AlacakKalemiAlaniEntity> = [];
  list: Array<{ takip_oncesi_mi: boolean, list: Array<TakipAltTipHesapAyarEntity> }> = [];
  localValue = this.value;
  onFly = false;
  debounce: any = null;

  get isKartaOzel() {
    return this.kartaOzel || this.kartaOzel === "";
  }

  mounted() {
    this.onValueChange();
  }

  /*controlGecikmeTazminati(){
    let ekAlan:any
    this.localValue.forEach((a:any)=>{
      if (a.gecikme_tazminati_mi){
        if (a.id==12){
          ekAlan = a
          ekAlan.alacak_kalemi_alani.isim ='Takip Öncesi Gecikme Tazminatı'
          ekAlan.oran=5
          ekAlan.oranlanan_hesap_ayar_id=1
        }
      }
    })

  }*/

  input() {
    this.$emit("value", this.localValue)
    this.$emit("input", this.localValue)
  }

  // @Watch("value")
  onValueChange() {
    this.items = [];
    this.renderList();

      /*this.controlGecikmeTazminati()*/

  }

  renderList(): void {
    let takipOncesiList: Array<any> = [];
    let takipSonrasiList: Array<any> = [];
    if (this.localValue) {
      this.localValue.forEach((val: any, key: number) => {
        this.items.push(val.alacak_kalemi_alani);
        if (val.alacak_kalemi_alani.takip_oncesi_mi)
          takipOncesiList.push(val);
        else
          takipSonrasiList.push(val);
      });
    }
    takipOncesiList.sort((a, b) => a.gosterim_sirasi - b.gosterim_sirasi);
    takipSonrasiList.sort((a, b) => a.gosterim_sirasi - b.gosterim_sirasi);
    takipSonrasiList.map((v, i) => (v.gosterim_sirasi = i + 1));
    this.list = [
      {
        takip_oncesi_mi: true,
        list: takipOncesiList
      },
      {
        takip_oncesi_mi: false,
        list: takipSonrasiList
      }
    ]
  }


  onInput(item: TakipAltTipHesapAyarEntity) {
    if (!this.isKartaOzel) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        let data: any = Object.assign({}, item);
        Object.keys(data).forEach((key) => {
          if (data[key] === null) {
            delete data[key];
          }
        });
        //Sabit input veri girisi saglandiysa oranlari silsin // emre
        if (data.sabit_tutar_mi === 1) {
          data.oran = 0;
          delete data.oranlanan_hesap_ayar_id;
        } else {
          data.sabit_tutar = 0;
        }
        this.$http.put(`/api/v1/takip-alt-tip-hesap-ayar/${data.id}`, data).then(() => {
          this.$emit('load');
        });
      }, 600);
    }
    this.input();
  }

  onEndNew(evt: any) {
    if (evt.oldIndex == evt.newIndex)
      return;
    this.onFly = true;
    let liste = [];
    if (evt.to.id == 'takipOncesi')
      liste = [...this.list[0].list];
    else
      liste = [...this.list[1].list];
    liste.map((v, i) => (v.gosterim_sirasi = i + 1));
    if (evt.to.id == 'takipOncesi')
      this.list[0].list = liste;
    else
      this.list[1].list = liste;

    this.localValue = [];
    this.localValue = [...this.list[0].list, ...this.list[1].list]
    this.localValue.map((v, i) => (v.gosterim_sirasi = i + 1));

    if (this.isKartaOzel) {
      this.input();
      this.onFly = false;
    } else {
      this.$http.post('/api/v1/takip-alt-tip-hesap-ayar/gosterim-sirasi', this.localValue).then(() => {
        this.$emit('load');
        this.onFly = false;
      });
    }
  }
}
