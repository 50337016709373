
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import TakipInfo from "@/components/infos/TakipInfo.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import AlacakList from "@/components/lists/AlacakList.vue";
import BorcluCardList from "@/components/lists/BorcluCardList.vue";
import AlacakliCardList from "@/components/lists/AlacakliCardList.vue";
import AlacakliCardInfo from "@/components/infos/AlacakliCardInfo.vue";
import NotList from "@/components/lists/NotList.vue";
import DokumanList from "@/components/lists/DokumanList.vue";
import KapakHesabiInfo from "@/components/infos/KapakHesabiInfo.vue";
import DilekceForm from "@/components/forms/DilekceForm.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import IsList from "@/components/lists/IsList.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";
import {TakipDurumu} from "@/enum/TakipDurumu";
import KapakHesabi from "@/components/infos/KapakHesabi.vue";
const VueWhatsappWidget: any = require('vue-whatsapp-widget/dist/vueWhatsappWidget.common');
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
type FaizDataType = {
  hesaplanabilir: boolean;
  data: TakipEntity | { message: string };
};

@Component({
  components: {
    KapakHesabi,
    IsList,
    FormDialog,
    DilekceForm,
    KapakHesabiInfo,
    DokumanList,
    NotList,
    AlacakliCardInfo,
    AlacakliCardList,
    BorcluCardList,
    AlacakList,
    TakipInfo,
     VueWhatsappWidget,
  }
})
export default class TakipDetayView extends Mixins(ObjectInputMixin) {
  @Prop() visibility!: boolean;
  @Ref('isList') readonly isListesi!: IsList;
  @Ref('notList') readonly notList!: NotList;

  takip: TakipEntity = <TakipEntity>{};
  faizData: FaizDataType = { hesaplanabilir: false, data: { message: '' } };
  fab = false;
  hover = false;
  show = false;
  show1 = false;
  show2 = false;
  isErrorShow = false;
  faizLoading=true;
  hesapTarihi !: Date
  TakipDurumu = TakipDurumu;
  aktifTakipDurumu!: TakipDurumu;

  @Watch('takip.id')
  onTakipChange() {
    this.aktifTakipDurumuKaydet();
  }

  aktifTakipDurumuKaydet() {
    this.aktifTakipDurumu = this.takip.takip_durumu;
  }
  @Watch('takip.borclular')
  onBorclularChange() {
    this.notList?.onTakipBorclulariChange();
  }

  @Watch('takip.alacaklilar')
  onAlacaklilarChange() {
    this.notList?.onTakipAlacaklilariChange();
  }

/* @Watch('item.takip_durumu')
  changeTakipDurumu(){
    if (this.aktifTakipDurumu ===TakipDurumu.kapali && this.item.takip_durumu ===TakipDurumu.acik){
      localStorage.setItem('oncekiTakip', this.aktifTakipDurumu);

      this.hesapTarihi = this.item.takip_tarihi
    }
   console.log('şuanki---->',this.item.takip_durumu)

   if (localStorage.getItem('oncekiTakip')===TakipDurumu.kapali && this.item.takip_durumu ===TakipDurumu.acik){
     this.hesapTarihi= this.item.takip_tarihi
   }

  /!*  console.log('önceki--->',this.aktifTakipDurumu)
    console.log('şuanki--->',this.item.takip_durumu)*!/
  }*/
  mounted() {
    let activeProcess = {id: this.$route.params.id, key: 'takip-duzenle'}
    this.$store.commit('setActiveProcess', activeProcess);
    this.load();
  }

  load() {

    if (this.$route.params.id) {
      this.faizLoading = true;
      this.$http.get('/api/v1/takip/' + this.$route.params.id).then((response) => {
        this.takip = response.data;
        this.$nextTick(() => (this.isListesi.search()));
       if (localStorage.getItem('oncekiTakip')===TakipDurumu.kapali && this.takip.takip_durumu ===TakipDurumu.acik){
          this.hesapTarihi= this.takip.takip_tarihi
          this.$http.post('/api/v1/takip/' + this.$route.params.id + '/faiz',{hesap_tarihi: this.hesapTarihi}).then((faizResponse: any)=>{
            this.faizData=faizResponse;
          }).finally(() => {
            this.faizLoading = false;
          });
        }else if ((this.aktifTakipDurumu ===TakipDurumu.acik && this.takip.takip_durumu ===TakipDurumu.kapali)){
          this.$http.post('/api/v1/takip/' + this.$route.params.id + '/faiz').then((faizResponse: any)=>{
            this.faizData=faizResponse;
          }).finally(() => {
            this.faizLoading = false;
          });
        }else{
          this.$http.post('/api/v1/takip/' + this.$route.params.id + '/faiz').then((faizResponse: any)=>{
            this.faizData=faizResponse;
          }).finally(() => {
            this.faizLoading = false;
          });
        }
      });
    }
  }

  takipAtla(sonrakiMi: boolean) {
    let filteredTakip = localStorage.getItem('filteredTakip');
    if (filteredTakip  != null) {
      let filtreliTakipler = JSON.parse(filteredTakip);
      if (sonrakiMi && filtreliTakipler.index != filtreliTakipler.data.length - 1) {
        filtreliTakipler.index++;
        localStorage.setItem('filteredTakip', JSON.stringify(filtreliTakipler));
        let takipId = filtreliTakipler.data[filtreliTakipler.index];
        this.$http.get('/api/v1/takip/' + takipId + '/takip-durumu').then((response) => {
          if (response.data != TakipDurumu.acilmamis)
            this.$router.push(ProgramPaths.icrapro + '/takip/' + takipId);
          else
            this.$router.push(ProgramPaths.icrapro + '/takip-ekle/' + takipId);
        })
      } else if (!sonrakiMi && filtreliTakipler.index != 0) {
        filtreliTakipler.index--;
        localStorage.setItem('filteredTakip', JSON.stringify(filtreliTakipler));
        let takipId = filtreliTakipler.data[filtreliTakipler.index];
        this.$http.get('/api/v1/takip/' + takipId + '/takip-durumu').then((response) => {
          if (response.data != TakipDurumu.acilmamis)
            this.$router.push(ProgramPaths.icrapro + '/takip/' + takipId);
          else
            this.$router.push(ProgramPaths.icrapro + '/takip-ekle/' + takipId);
        })
      }
    }
  }

  isAuthorized(keySuffix: string) {
    let ap = this.$store.state.activeProcesses;
    let authKey = null;
    if (keySuffix[0] !== '-')
      keySuffix = '-' + keySuffix;
    if (ap.filter((item: any) => item.key === "takip-ac").length > 0)
      authKey = "takip-ac";
    else if (ap.filter((item: any) => item.key === "takip-duzenle").length > 0)
      authKey = keySuffix;
    if (!authKey)
      return false;
    return this.$helper.isAuthorized(authKey)
  }

  canliDestek() {
    window.open("https://livesupport.zirve-bilgisayar.com/canlidestek/chat.php", "_blank");
  }
}
