import { render, staticRenderFns } from "./TopluTebligatHazirlaFormWizard.vue?vue&type=template&id=05a045e8"
import script from "./TopluTebligatHazirlaFormWizard.vue?vue&type=script&lang=ts"
export * from "./TopluTebligatHazirlaFormWizard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports